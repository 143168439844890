import { renderBlocks } from '@/components/blocks';
import { Layout } from '@/components/layout/Layout';
import { BASE_REVALIDATE } from '@/lib/constants';
import { useDynamicYield } from '@/lib/dynamicYield';
import {
  allProgrammesQuery,
  allSlugsFromPagesQuery,
  latestPostsQuery,
  localeTranslationsQuery,
  navigationQuery,
  navigations_exQuery,
  pageQuery,
  siteSettingsQuery,
} from '@/lib/queries';
import { getClient, readToken } from '@/lib/sanityUtils';
import {
  buildLayoutProp,
  getSettledValue,
  getValidLocale,
  queryParametersWithAudience,
} from '@/lib/utils';

import type { PageType } from '@/lib/dynamicYield';
import type { SanityNavigationType } from '@/types/sanity';
import type {
  Navigation_exType,
  PageData,
  SiteSettings,
  Translation,
} from '@/types/shared';
import type {
  GetStaticPaths,
  GetStaticProps,
  InferGetStaticPropsType,
} from 'next';

export const getStaticPaths = (async () => {
  const client = getClient();

  const allSlugs = await client.fetch(allSlugsFromPagesQuery());
  const staticSlugs = [
    'careers',
    'events',
    'impact-report-2023',
    '404',
    'employer-contact-form',
  ];

  return {
    paths: allSlugs
      .filter((page) => !staticSlugs.includes(page.slug.current))
      .map((page) => ({
        params: { slug: page.slug.current.split('/') },
        locale: page._lang,
      })),

    fallback: 'blocking',
  };
}) satisfies GetStaticPaths;

export const getStaticProps = (async ({
  params: { slug },
  locale,
  draftMode = false,
}) => {
  const validLocale = getValidLocale(locale);

  if (!validLocale) {
    return {
      notFound: true,
    };
  }
  const client = getClient(draftMode ? { token: readToken } : undefined);

  const navigations_ex = await client.fetch<Navigation_exType[]>(
    navigations_exQuery({ locale: validLocale }),
  );

  /*
    Dynamic routing returns undefined on the empty slug,
    so we have to parse it further to the CMS to get the data for a homepage.
   */

  slug = slug ? (slug as string[]).join('/') : '/';

  const pageData = await client.fetch<PageData>(
    pageQuery({
      ...queryParametersWithAudience(slug, navigations_ex),
      locale: validLocale,
    }),
  );

  if (!pageData) {
    return {
      notFound: true,
    };
  }

  const siteSettingsPromise = client.fetch<SiteSettings>(
    siteSettingsQuery({ locale: validLocale }),
  );

  const navigationPromise = client.fetch<SanityNavigationType>(
    navigationQuery({
      locale: validLocale,
    }),
  );

  const translationsPromise = client.fetch<Translation[]>(
    localeTranslationsQuery(pageData),
  );

  const getLatestPostsData = async () => {
    if (
      pageData?.blocks?.find(
        (block) =>
          block._type === 'latestPosts' || block._type === 'articleGrid',
      )
    ) {
      return client.fetch(latestPostsQuery({ locale: validLocale }));
    }
    return Promise.reject();
  };

  const getProgrammeCatalogueData = async () => {
    if (
      pageData?.blocks?.find((block) => block._type === 'programmeCatalogue')
    ) {
      return client.fetch(allProgrammesQuery({ region: validLocale }));
    }

    return Promise.reject();
  };

  const [
    navigationSettled,
    siteSettingsSettled,
    translationsSettled,
    latestPostsDataSettled,
    programmeCatalogueDataSettled,
  ] = await Promise.allSettled([
    navigationPromise,
    siteSettingsPromise,
    translationsPromise,
    getLatestPostsData(),
    getProgrammeCatalogueData(),
  ]);

  return {
    props: {
      pageData,
      slug,
      locale: validLocale,
      siteSettings: getSettledValue(siteSettingsSettled),
      latestPostsData: getSettledValue(latestPostsDataSettled),
      programmeCatalogueData: getSettledValue(programmeCatalogueDataSettled),
      translations: getSettledValue(translationsSettled),
      navigation: getSettledValue(navigationSettled),
      navigations_ex,
      draftMode,
      token: draftMode ? readToken : '',
    },
    revalidate: Number(process.env.REVALIDATE || BASE_REVALIDATE),
  };
}) satisfies GetStaticProps;

const Page = ({
  pageData,
  siteSettings,
  locale,
  latestPostsData,
  programmeCatalogueData,
  navigation,
  navigations_ex,
  draftMode,
  translations,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { recommendationContext } = useDynamicYield(
    locale,
    pageData?.dynamicYield?.contextPageType as PageType,
    [pageData?.dynamicYield?.contextDataType] ?? [],
  );

  return (
    <Layout
      SEO={pageData?.SEO}
      localeTranslations={translations}
      canonicalPagePath={pageData?.canonicalPage?.slug.current}
      siteSettings={siteSettings}
      preview={draftMode}
      navigation={navigation}
      navigations_ex={navigations_ex}
      recommendationContext={recommendationContext}
      audienceRef={pageData?.audienceRef}
      layout={buildLayoutProp(pageData)}
    >
      {renderBlocks(pageData?.blocks, {
        latestPostsData,
        programmesCatalogue: programmeCatalogueData,
        pageAudience: pageData?.audienceRef,
        isAudienceSwitcherEnabled: siteSettings?.isAudienceSwitcherEnabled,
      })}
    </Layout>
  );
};

export default Page;
